import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Top from "../pages/top";
import MarkerBaseAr from "../pages/marker-base-ar";
import HiroMarkerAr from '../pages/hiro-marker-ar';

function Root() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='' element={<Top/>} />
          <Route path='/marker-base-ar' element={<MarkerBaseAr/>} />
          <Route path='/hiro-marker-ar' element={<HiroMarkerAr/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Root;