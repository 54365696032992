import { useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import WorkCard from '../components/work-card';
import { ReactComponent as Search } from '../assets/svg/search.svg';
import { works_data } from '../utils/works_data';

function Top() {
  const [searchResult, setSearchResult] = useState(works_data);
  const myWorker = new Worker("search.worker.js");

  function searchFormChange(event:any) {
    let search_info = {
      base_data: works_data,
      search_word: event.target.value
    }

    myWorker.postMessage(search_info);
  }

  myWorker.onmessage = function(event) {
    setSearchResult(event.data)
  }

  return (
    <>
      <Header />
      <main className="min-h-screen container">
        <h2 className="mt-9 text-6xl font-bold italic">Try</h2>
        <p className="mt-3 font-bold">気になった技術を試したサイトです。<br/>
          ポートフォリオサイトを閲覧したい場合は
          <a className="text-purple-300   hover:opacity-80" href="https://www.ryota.dev/">こちら</a>
          をクリックしてください。
        </p>
        <div className="flex w-full items-center mt-6 p-3 border-999 bg-222 font-bold rounded-lg">
          <Search className="w-6 h-6 mr-2 text-999"/>
          <input className="w-full bg-222 border-0 outline-none   placeholder:text-gray-400" onChange={searchFormChange} autoCapitalize="none" autoComplete="off" autoCorrect="off" name="search_query" type="text" spellCheck="false" placeholder="Search" />
        </div>
        <div className="grid grid-cols-3 gap-x-6   md:grid-cols-2  sm:grid-cols-1">
          {
            searchResult.map((data:any) =>
              <WorkCard title={data.title} emoji={data.emoji} route={data.route} key={data.title} />
            )
          }
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Top;
