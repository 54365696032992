const Footer: React.FC = () => {
  return (
    <div className="flex justify-center w-full h-14 bg-111 border-t-999">
      <footer className="flex justify-end max-w-700px w-full py-2">
        <p className="text-lg text-gray-300">Copyright © 2022 - ryota iso</p>
      </footer>
    </div>
  )
}

export default Footer