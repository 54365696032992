import Button from "./button"

const Header: React.FC = () => {
  return (
    <div className="flex justify-center w-full bg-111 border-b-999">
      <header className="flex justify-between items-center max-w-700px w-full py-2   lg:mx-9   md:mx-3">
        <h1 className="text-2xl font-bold">Try-Portfolio</h1>
        <Button text={"ポートフォリオ"} url={"https://www.ryota.dev"} external={true}></Button>
      </header>
    </div>
  )
}

export default Header