import { Link } from 'react-router-dom';

type Props = {
  text: string;
  url?: string;
  external: boolean;
};

const Button: React.FC<Props> = (props) => {
  const {
    text,
    url,
    external,
  } = props;

  return (
    <>
      <button className="px-5 py-2 bg-222 border-999 font-bold rounded-lg   hover:opacity-70">
        {url === null
          ? {text}
          : <>
              {external
                ? <a href={url}>{text}</a>
                : <Link to={url || "notfound"}>{text}</Link>
              }
            </>
        }
      </button>
    </>
  )
}

export default Button