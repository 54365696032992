import { Link } from "react-router-dom";
import twemoji from "twemoji";

type Props = {
  title: string;
  emoji: string;
  route: string;
};

const WorkCard: React.FC<Props> = (props) => {
  const {
    title,
    emoji,
    route,
  } = props;

  return (
    <button>
      <Link to={route}>
        <div className="flex flex-col justify-center items-center w-full h-52 mt-5 p-6 bg-222 border-999 rounded-lg   hover:opacity-70">
          <span
            dangerouslySetInnerHTML={{
              __html: twemoji.parse(emoji)
            }}
          />
          <p className="mt-3 font-bold">{title}</p>
        </div>
      </Link>
    </button>
  )
}

export default WorkCard