type search_guide = {
  base_word: string;
  guide_words: string[];
}

type work_data = {
  title: string;
  emoji: string;
  search_guide: search_guide[];
  route: string;
}

// guide_wordsの記述ルール
// 全ての漢字に読みを用意する
// 英字を入れる場合は全て小文字に
export const works_data:work_data[] = [
  {
    title: "Hiroマーカーを利用したマーカーベースAR",
    emoji: "📷",
    search_guide: [
      {
        base_word: "Hiroマーカー",
        guide_words: ["qrコード"],
      },
      {
        base_word: "利用",
        guide_words: ["りよう", "使用", "しよう", "活用", "かつよう"],
      },
      {
        base_word: "AR",
        guide_words: ["ar.js", "拡張現実", "かくちょうげんじつ", "a-frame"],
      },
    ],
    route: "hiro-marker-ar",
  },
  {
    title: "オリジナルマーカーを利用した、AR名刺",
    emoji: "🔲",
    search_guide: [
      {
        base_word: "オリジナル",
        guide_words: ["独自マーカー", "どくじまーかー"],
      },
      {
        base_word: "利用",
        guide_words: ["りよう", "使用", "しよう", "活用", "かつよう"],
      },
      {
        base_word: "AR",
        guide_words: ["ar.js", "拡張現実", "かくちょうげんじつ", "a-frame"],
      },
    ],
    route: "marker-base-ar",
  }
];