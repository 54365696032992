import { useEffect, useState } from 'react';

function HiroMarkerAr() {
  const getWindowSize = () => {
    type window_data = {
      width: number,
      height: number
    }
    const window_size:window_data = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    return window_size;
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const onResize = () => {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      {/* TODO:ローディングを追加 */}
      {/* MEMO:2020/06/01までは動作を保証(レポートへリンクを記載するため) */}
      <iframe src="./assets/hiro-marker-ar/hiro-marker-ar.html" width={windowSize.width} height={windowSize.height} frameBorder="0"></iframe>
    </>
  );
}

export default HiroMarkerAr;
